import React from 'react'
import styles from '../styles/Stories.module.css'
import kalastusImage from '../images/kalastus.jpg'

export default () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.story}>
        <h1>Asiakastarina #1</h1>
        <h3>Kylmälaitteiden seurantajärjestelmä hälytti ajoissa - mittavat vahingot vältettiin</h3>
        <img loading="lazy" width={256} height={341} src="https://matitalo.fi/assets/img/galleria8.jpeg"
             alt="Mätitalon kalaa"/>
        <p>
          Hälytys tuli juhannuksena aamupäivällä: pakastimen lämpötila oli noussut yli sallitun raja-arvon.
          Yrittäjät ehtivät ajoissa paikalle ja pakastimen arvokas sisältö saatiin turvaan.
          Myös myöhemmin sattuneeseen sähkökatkokseen pystyttiin järjestelmän ansiosta reagoimaan.
          <q>Mielenrauha on kaikista tärkeintä, se ei ole rahalla korvattavissa</q> toteaa <strong>Marko Räsänen</strong>, toinen yrittäjistä.
          <q>Järjestelmän ansiosta tiedämme koko ajan tuotantoketjun lämpötilat.
          Useamman kerran päivässä tulee seurattua tilannetta, vaikka hälytyksiä ei tule.
          Mukavaa katsoa illallakin vielä, että kaikki on hyvin</q> yrittäjät kertovat.
        </p>
        <p>
          <strong><a href='https://matitalo.fi' target='_blank'>Mätitalo Oy</a></strong> on kalatuotteita valmistava yritys Karttulasta, jota pyörittävät yrittäjät Marko Räsäsen ja <strong>Niko Humalamäki</strong>.
          Laadukkaat ja hyvän makuiset kalatuotteet ovat yrityksen kilpailuvaltti.
          Vuonna 2024 aloittaneet uudet yrittäjät tarttuivat toimeen ja hankkivat samana vuonna myös ratkaisun laadunvalvontaa varten.
          Heillä siihen kuuluu kuusi etäluettavaa lämpötila-anturia sekä <strong>SensorMonitor</strong>-järjestelmä,
          joka lähettää hälytyksen joko tekstiviestinä, Push-viestinä tai sähköpostilla,
          jos lämpötila alkaa nousta yli asetettujen raja-arvojen.
        </p>
        <p>
          Tuotteiden laadun kannalta on ensiarvoisen tärkeää, että lämpötilat pysyvät oikeina tuotantotilassa,
          savustamossa ja säilytyskylmiöissä. Myös pakastimen lämpötilan seuranta on välttämätöntä.
          Järjestelmä helpottaa myös omavalvontaa ja terveystarkastajan kanssa asiointia.
          Tarkasteltava ajanjakso on helppo näyttää viranomaiselle suoraan SensorMonitorin mobiili- tai web-sovelluksesta,
          josta mahdolliset lämpötilapoikkeamat on helppo havaita. Työläästä lomakkeiden täyttelystä on voitu luopua kokonaan.
        </p>
        <p>
          Räsäsen ja Humalamäen mielestä SensorMonitor-järjestelmä oli todella helppo ottaa käyttöön ja tukea on saatu aina tarvittaessa.
          <q>Uskomattoman hyvä palvelu. 10+ täytyy antaa tästä palvelusta</q> yrittäjät toteavat.
        </p>
      </div>
      <div className={styles.story}>
        <h1>Asiakastarina #2</h1>
        <h3>Kalastuskoskien lämpötilaseuranta tuottaa tärkeää tietoa harrastajille ja asiantuntijoille</h3>
        <img loading="lazy" width={256} height={455} src={kalastusImage} alt="Kalastusta laiturilta"/>
        <p>
          Kalastuskoskien lämpötiloja tulee seurata, sillä kalastus on keskeytettävä veden lämpötilan noustessa yli 21 asteen.
          Lämpötilan nouseminen vähentää vedessä olevan liukoisen hapen määrää, mikä puolestaan aiheuttaa rasitusta taimenille.
          Vesien lämpötilat ovat nousseet yhä useampina kesinä kalojen kannalta liian korkeiksi, ja näin tulee käymään
          ilmaston muuttuessa todennäköisesti myös tulevaisuudessa.
        </p>
        <p>
          <strong>Metsähallitus</strong> on käyttänyt osassa hallinnoimissaan vapakalastuskohteissa <strong>SensorMonitor</strong>-lämpötilasensoreita
          muutaman vuoden ajan. SensorMonitor-palvelussa voi veden lämpötilaa seurata reaaliajassa mistä päin Suomea tahansa.
          Palvelu on toiminut kalastuksen eritysasiantuntija <strong>Eetu Karhusen</strong> mukaan hyvin.
          <q>Joitain pieniä haasteita ollut teknisellä puolella, mutta ne ovat olleet pääosin ympäristöstä ja käyttäjistä lähtöisin</q>.
        </p>
        <p>
          Karhunen kertoo kokemuksistaan SensorMonitorin käyttäjänä: <q>Lähettävät lämpötilasensorit auttavat lämpötilojen
          seurannassa niin työntekijöitä kuin myös asiakkaita. Sensorit keräävät veden lämpötilan ja lähettävät ne kohdesivuille.
          Asiakkaita tieto auttaa toivottavasti ennakoimaan kalareissuja tai niille lähtemistä. Asiantuntijoita tieto
          auttaa kohteiden avaamisessa ja sulkemisessa, niin ettei paikan päälle tarvitse mennä veden lämpöä mittailemaan.
          Kustannussäästöjä tulee, kun veden lämpöä ei tarvitse käydä mittailemassa paikan päällä.
          Kustannussäästöjä on vaikea arvioida, mutta ne on kuitenkin merkittävät.</q>
        </p>
        <p>
          Eetu Karhunen kehottaa reippaasti ottamaan yhteyttä SensorMonitoriin. Yrittäjät auttavat kokonaisuuden
          suunnittelussa ja laitteiden hankinnan arvioinnissa. <q>Saimme hyvin tukea käyttöön ja käyttöönotto oli helppoa.
          Teknistä tukea olemme saaneet tarvittaessa ja uusia laitteita nopeasti hajonneiden tilalle.</q>
        </p>
        <p>
          Lisätietoa vastuullisesta kalastuksesta saat <a href='https://www.eraluvat.fi/kalastus/kalastajan-tietopankki/ohjeita-vastuulliselle-kalastajalle' target='_blank'>Metsähallituksen Eräluvat -palvelusta</a>.
        </p>
      </div>
    </div>
  )
}
